import React from 'react';
import styled from "styled-components";
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import carouselStyles from '../carouselStyles.css';
import DynamicCarousel from "./DynamicCarousel";

const ItemContainer = styled.div`
	display:flex;
	flex-direction:column;
	background-color:#000;
	width:100%;
	height:100%;
	overflow:hidden;
	padding:25px 20px;
	border:solid 1px yellow;
`;

const CarouselWrapper = styled.div`
	display:block;
	max-width:100%;
	height:100%;
	display:flex;
	justify-content:center;
	.carousel-root{
		display:block;
		width:100%;
		height:100%;
	}
`;


const ProjectItem = ({ opened, pid }) => {
	if(!pid){
		return (<ItemContainer  />)
	}


	return (
		<ItemContainer>
			<CarouselWrapper>
				 {opened && <DynamicCarousel pid={pid} />}
			</CarouselWrapper>
    </ItemContainer>
	);
};

export default ProjectItem;