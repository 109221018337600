import React, { Component } from 'react';
import styled from "styled-components"
import BorderedImg from "../imgs/BorderedImg";
import Project from "./Project";

const Container = styled.div`
	display:flex;
	flex-direction:row;
	flex-wrap:wrap;
	justify-content:center;
	align-items:center;
	width:100%;
`;

const Item = styled.div`
	display:block;
	margin:20px;
	width:290px;
	@media only screen and (${props => props.theme.mobileThreshold}) {
		width:310px;
	}
`;

class PortfolioGrid extends Component {
	constructor(props) {
		super(props);

		this.state = {
			count: 0,
			isProjectOpen:false,
			currLeftOffset:0,
			currTopOffset:0,
			currHeight:0,
			currWidth:0,
			pid:null,
		};

		 // Initialise an empty array for project refs
		 this.projectRefs = [];
	}

	componentDidMount() {
    this.int = setInterval(() => {
			this.setState({
				count:this.state.count + 1
			}, () => {
				if(this.state.count >= 12){
					clearInterval(this.int);
				}
			})
		}, 180);
  }
	componentWillUnmount() {
    clearInterval(this.int);
	}

	onImageSelected(ref,pid) {
		this.setState({
			currLeftOffset:ref.current.offsetLeft,
			currTopOffset:ref.current.offsetTop,
			currWidth:ref.current.offsetWidth,
			currHeight:ref.current.offsetHeight,
			isProjectOpen:true,
			pid
		})
	}

	toggleHandler() {
		this.setState({
			isProjectOpen:!this.state.isProjectOpen
		})
	}

	
	render() {
    const projects = [
      { id: "p1", imgURL: "portfolio/p1/boxas-p1-a.jpg" },
      { id: "p2", imgURL: "portfolio/p2/boxas-p2-a.jpg" },
      { id: "p3", imgURL: "portfolio/p3/boxas-p3-a.jpg" },
      { id: "p4", imgURL: "portfolio/p4/boxas-p4-a.jpg" },
      { id: "p5", imgURL: "portfolio/p5/boxas-p5-a.jpg" },
      { id: "p6", imgURL: "portfolio/p6/boxas-p6-a.jpg" },
    ];

    const status = this.state.count - 5;

    return (
      <React.Fragment>
       <Container>
  {projects.map((project, index) => {
    const ref = React.createRef();
    this.projectRefs[index] = ref;

    return (
      <Item key={project.id}>
        <BorderedImg
          ref={ref}
          imgURL={project.imgURL}
          pid={project.id}
          onSelected={this.onImageSelected.bind(this)}
          interact
          size={status > index ? "full" : "shrink"}
        />
      </Item>
    );
  })}
</Container>
        <Project
          opened={this.state.isProjectOpen}
          leftOffset={this.state.currLeftOffset}
          topOffset={this.state.currTopOffset}
          itemHeight={this.state.currHeight}
          itemWidth={this.state.currWidth}
          toggleHandler={this.toggleHandler.bind(this)}
          pid={this.state.pid}
        />
      </React.Fragment>
    );
  }

}



export default PortfolioGrid;