import React from 'react';
import styled from "styled-components"


import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";


const BorderedImgContainer = styled.div`
	display:flex;
	position:relative;
	justify-content:center;
	align-items:center;
	&.clickable{
		cursor: pointer;
		transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
		transition-duration: 200ms;
		transition-property: all;
		:hover, :focus{
			transform: scale(1.1);
		}
	}
`;
const BorderBase = styled.div`
	display:block;
	background-color:${props => props.theme.red};
	min-height:3px;
	min-width:3px;
	position:absolute;
	z-index:10;
	transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
	transition-duration: 550ms;
	transition-property: top, left, bottom, right, width, height;
`;
const BorderTop = styled(BorderBase)`
	top:7%;
	width:99%;
	margin-left:1%;
	transition-delay: 125ms;
	&.shrink{
		top:30%;
		width:49%;
	}
`
const BorderRight = styled(BorderBase)`
	right:7%;
	height:95%;
	transition-delay: 75ms;
	&.shrink{
		right:30%;
		height:45%;
	}
`
const BorderBottom = styled(BorderBase)`
	bottom:7%;
	width:97%;
	margin-left:1%;
	transition-delay: 150ms;
	&.shrink{
		bottom:30%;
		width:47%;
	}
`
const BorderLeft = styled(BorderBase)`
	left:7%;
	height:100%;
	transition-delay: 100ms;
	&.shrink{
		left:30%;
		height:50%;
	}
`
const BorderImgMask = styled.div`
	display:block;
	position:absolute;
	z-index:5;
	width:86%;
	height:86%;
	overflow:hidden;
	opacity:1;
	transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
	transition-duration: 550ms;
	transition-property: width, height, opacity;
	transition-delay: 200ms;
	&.shrink{
		width:40%;
		height:40%;
		opacity:0;
	}
`;

const BorderImgSpacer = styled.div`
	display:flex;
	width:100%;
	height:100%;
	visibility: hidden;
`;

const StyledImg = styled(Img)`
	width:100%;
`;

const BorderedImg = React.forwardRef(({ size, interact, onSelected, imgURL,pid }, ref) => {

	const data = useStaticQuery(graphql`
    query {
      allFile(filter: { sourceInstanceName: { eq: "images" } }) {
        nodes {
          relativePath
          childImageSharp {
            fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `);

	// Find the image that matches the provided `imgURL` (relative path)
  const image = data.allFile.nodes.find((node) => node.relativePath === imgURL);

	 if (!image) {
    console.error(`Image not found for imgURL: ${imgURL}`);
    return null;
  }


	return (
		<BorderedImgContainer
			ref={ref}
			className={interact ? "clickable" : ""}
			onClick={() => {
				if(onSelected){
					onSelected(ref, pid)
				}
			}}
		>
			<BorderTop className={size === "full" ? "" : "shrink"}/>
			<BorderRight className={size === "full" ? "" : "shrink"}/>
			<BorderBottom className={size === "full" ? "" : "shrink"}/>
			<BorderLeft className={size === "full" ? "" : "shrink"}/>
			<BorderImgMask className={size === "full" ? "" : "shrink"}>
				<StyledImg loading="eager" fluid={image.childImageSharp.fluid} alt={pid} />
			</BorderImgMask>
			<BorderImgSpacer>
				<StyledImg loading="eager" fluid={image.childImageSharp.fluid} alt={pid} />
			</BorderImgSpacer>
		</BorderedImgContainer>
	);
});

export default BorderedImg;