import React, { useState, useEffect } from 'react';
import styled from "styled-components"
import Testimonial from "../testimonials/Testimonial";
import BorderedImg from "../imgs/BorderedImg";
import FadeIn from "../FadeIn";

const ImgWrapper = styled.div`
	display:flex;
	flex:1 0 auto;
	/* border:solid 1px yellow; */
	
	&.mobile{
		display:none;
		@media only screen and (${props => props.theme.mobileThreshold}) {
			display:flex;
		}
	}
	&.large{
		max-width:800px;
		width:50%;
		@media only screen and (${props => props.theme.mobileThreshold}) {
			display:none;
		}
	}
`;
const Wrapper = styled.div`
	display:flex;
	flex-direction:column;
`;



const HomeImg = ({classType}) => {
	const [status, setStatus] = useState(0);
	useEffect(() => {
		setTimeout(() => {
			setStatus(1);
		},1000)
	 }, []);
	 
	return (
		<ImgWrapper className={classType}>
			<Wrapper>

				<BorderedImg imgURL="portfolio/p1/boxas-p1-a.jpg" size={status > 0 ? "full" : "shrink"}/>

				<FadeIn duration="900" delay="1300">
					<Testimonial testimonial="Neil was great to work with in creating our dream kitchen and extension for our home, From our initial conversations, Neil quickly realised the style and feel that we were looking for and presented us with a number of excellent ideas for us to consider. Neil helped and walked us though each stage, from concept to build with his knowledge and expertise." author="Mr &amp; Mrs Bond"/>
				</FadeIn>
				
			</Wrapper>
			
		</ImgWrapper>
	);
};

export default HomeImg;