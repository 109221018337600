import React, { Component } from 'react';
import styled from "styled-components"
import BorderedImg from "../imgs/BorderedImg"
import ServiceIcons from "./ServiceIcons";


const Container = styled.li`
	display:block;
	padding:0;
	margin:0;
	margin-bottom:20px;
	/* border:solid 1px yellow; */
`;
const Wrapper = styled.div`
	margin:0 auto;
	display:flex;
	flex-direction:row;
	width:80%;
	position: relative;
	&.left{

	}
	&.right{
		flex-direction:row-reverse;
	}
	@media only screen and (${props => props.theme.mobileThreshold}) {
		width:100%;
		flex-direction:column;
		&.left,	&.right{
			flex-direction:column;
		}
	}
	@media only screen and (max-width: 1200px) {
		width:90%;
	}
`;
const Details = styled.div`
	flex:1 0 0%;
	display:flex;
	flex-direction:column;
	margin-left:10px;
	padding-top:15px;
	position: relative;
	z-index: 10;
	@media only screen and (${props => props.theme.mobileThreshold}) {
		margin-left:0;
	}
`;
const Title = styled.div`
	background-color:${props => props.theme.red};
	padding:0 20px;
	border-radius:2px;
	cursor: pointer;
	display:flex;
	justify-content:space-between;
	align-items:center;
`;

const TitleH4 = styled.h4`
	color:#000;
	margin:0.25rem;
	text-transform:uppercase;
`;

const Info = styled.div`
	overflow-y:hidden;
	transition-property: max-height;
	transition-duration: 750ms;
	transition-timing-function: ease-out;
	&.opened{
		max-height:100vh;
	}
	&.closed{
		max-height:0;
		transition-duration: 200ms;
	}
`;
const InfoP = styled.p`
	padding:10px 20px;
`;
const ToggleIcon = styled.div`
	display:block;
	width:30px;
	height:30px;
`;
const SVGIcon = styled.svg`
	width:100%;
	height:100%;
`;


const ImageItem = styled.div`
	display:block;
	margin-bottom:20px;
	width:320px;
	
	&.left{
		transform: rotate(-1deg);
	}
	&.right{
		transform: rotate(1deg);
	}
	@media only screen and (max-width: 900px) {
		width:270px;
	}
	@media only screen and (${props => props.theme.mobileThreshold}) {
		width:100%;
		margin-bottom:-45px;
		&.left, &.right{
			transform: rotate(0deg);
		}
	}
`;
class ServiceItem extends Component {

	constructor(props) {
		super(props);

		this.state = {
			isOpened: false,
		};
	}

	toggle(){
		const flag = this.state.isOpened;
		this.setState({ isOpened: !flag })
	}



	render() {
		const svgToggleIcon = this.state.isOpened ? (
			<SVGIcon viewBox="0 0 20 20">
				<path d="M10 0a10 10 0 1 1 0 20 10 10 0 0 1 0-20zm0 2a8 8 0 1 0 0 16 8 8 0 0 0 0-16zm2 8v5H8v-5H5l5-5 5 5h-3z"/>
			</SVGIcon>
		) : (
			<SVGIcon viewBox="0 0 20 20">
				<path d="M10 20a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-2-8V5h4v5h3l-5 5-5-5h3z"/>
			</SVGIcon>
		)
		
		const status = 1;
		return (
			<Container>
				<Wrapper className={this.props.side}>
					{/* <ServiceIcons iconCode={this.props.icon}/> */}
					
					<ImageItem className={this.props.side}>
						<BorderedImg 
							imgURL={this.props.icon}
							size={status > 0 ? "full" : "shrink"} 
						/>
					</ImageItem>
					<Details>
						<Title onClick={() => this.toggle()}>
							<TitleH4>{this.props.number} - {this.props.title}</TitleH4>
							<ToggleIcon>
								{svgToggleIcon}
							</ToggleIcon>
						</Title>
						<Info className={this.state.isOpened ? "opened" : "closed"}>
							<InfoP>{this.props.info}</InfoP>
						</Info>
					</Details>
				</Wrapper>
				
			</Container>
		);
	}
}




export default ServiceItem
