import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import { Carousel } from "react-responsive-carousel";

const DynamicCarousel = ({ pid }) => {
  // Query images dynamically
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { sourceInstanceName: { eq: "images" } }) {
        nodes {
          name
          relativeDirectory
          childImageSharp {
            fluid(maxWidth: 1200, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `);

  // Filter images for the current project
  const projectImages = data.allFile.nodes.filter(
    (node) => node.relativeDirectory === `portfolio/${pid}`
  );

  if (!projectImages.length) {
    return <p>No images available for this project.</p>;
  }

  return (
    <Carousel
      autoPlay={false}
      showArrows={false}
      showStatus={false}
      showThumbs={false}
      dynamicHeight
    >
      {projectImages.map((image) => (
        <Img
          key={image.name}
          fluid={image.childImageSharp.fluid}
          alt={image.name}
        />
      ))}
    </Carousel>
  );
};

export default DynamicCarousel;