import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"
import ServiceItem from "../components/services/ServiceItem"
import { ServicesTitle } from '../components/titles/Title'
import FadeIn from "../components/FadeIn";

const Wrapper = styled.div`
	display:flex;
	width:100%;
	flex:1 0 auto;
	@media only screen and (${props => props.theme.mobileThreshold}) {
		flex-direction:column
	}
`;
const LeftText = styled.div`
	flex:1;
	padding-right:20px;
	padding-bottom:20px;
	@media only screen and (${props => props.theme.mobileThreshold}) {
		width:100%
	}
`;

const Title = styled.h1`
	margin-top:0;
	line-height:1.2;
`;
const ServiceItemsList = styled.ul`
	list-style:none;
	margin:0;
	padding:0;
`;

const ListIntro = styled.div`
	display:block;
	h3{
		text-align:center;
		margin:5px;
	}
`;
const ServicesPage = () => (
  <Layout>
    <SEO title="Boxas - Services" />
    <Wrapper>
			<LeftText>
				<ServicesTitle />
				<FadeIn duration="900" delay="1000">
					<p>Box Architectural Services Ltd offers a full range of architectural services for your project whilst assessing the needs of both the client and end users with the aid of an agreed project brief. Evaluating and advising upon environmental and regulatory applications which may include measured surveys, planning and building regulation applications and all associated work tailored to the clients agreed form of appointment, which may include site certification and contract administration. I am able to take a project from inception to completion and project manage the design phase of any project.</p>
					<ListIntro>
						<h3>The Process made Simple</h3>
					</ListIntro>
					
					<ServiceItemsList>
						<ServiceItem 
							icon="services/1-consultation.jpg"
							number="1"
							title="Consultation" 
							info="Initial meeting is held to take your brief and review the site constraints and allow a fee quote to be prepared and agreed"
							side="left"
						/>

						<ServiceItem 
							icon="services/2-feasibility.jpg"
							number="2"
							title="Feasibility" 
							info="Initial sketches are prepared for discussions to allow detailed design to commence or a possible pre-application enquiry to be submitted."
							side="right"
						/>

						<ServiceItem 
							icon="services/3-planning.jpg"
							number="3"
							title="Planning" 
							info="Detailed design is prepared and agreed with you. All necessary consultants are appointed prior to the submission of a planning application so it can be considered. The planning authority can take between 8-13 weeks to determine the application."
							side="left"
						/>

						<ServiceItem 
							icon="services/4-conditions.jpg"
							number="4"
							title="Conditions" 
							info="Once planning is approved the planning conditions that may prevent a commencement on site are to be discharged. The planning authority can take up to 8 weeks to discharge these conditions"
							side="right"
						/>

						<ServiceItem 
							icon="services/5-building-regs.jpg"
							number="5"
							title="Building Regulations" 
							info="With planning permission secured, building control are to be notified of your project and approve the information that is provided prior to a commencement on site. Generally, their response is received within 15 working days. In most cases this can be run concurrently with discharging the conditions. Any conditions are discharged as required thereafter if a building control issue a conditional approval."
							side="left"
						/>

						<ServiceItem 
							icon="services/6-additional-service.jpg"
							number="6"
							title="Additional Services" 
							info="Further information such as working drawings that include detailed design, schedules, SAP calculations and even arranging contracts with the contractor, carrying out site inspections and overseeing payment schedules should this be required by you."
							side="right"
						/>

						<ServiceItem 
							icon="services/7-commencement.jpg"
							number="7"
							title="Commencement" 
							info="Once planning and building regulation approvals have been obtained you can start on site."
							side="left"
						/>
				
						<ServiceItem 
							icon="services/8-completion.jpg"
							number="8"
							title="Completion" 
							info="Hand over and enjoy"
							side="right"
						/>

					</ServiceItemsList>
				</FadeIn>
				
			</LeftText>

			
		</Wrapper>
  </Layout>
)

export default ServicesPage